
import React, { useState, useEffect } from 'react';

function PrintForm({ 
  onSubmit,
  onAddAnotherFile,
  numPages,
  uploadedFiles, 
  setUploadedFiles,
  uploadedFilesInfo,
  setUploadedFilesInfo,
  handleFileUpload
   } ) {
  const [color, setColor] = useState('');
  const [size, setSize] = useState('');
  const [material, setMaterial] = useState('');
  const [copiesImage, setCopiesImage] = useState('1');
  const [orientation, setOrientation] = useState('Vertical');
  const [copies, setCopies] = useState(1);
  const [numPagesF, setNumPagesF] = useState(numPages);
  const [doubleSided, setDoubleSided] = useState(false);
  const [hooped, setHooped] = useState(false);
  const [email, setEmail] = useState('');
  const [comments, setComments] = useState('');
  const [totalP, setTotalP] = useState(0);
  const [availableSizes, setAvailableSizes] = useState([]);
  const [errorS, setErrorS] = useState('');
  const [errorDC, setErrorDC] = useState('');
  const [errorP, setErrorP] = useState('');
  const [allPages, setAllPages] = useState(true); // Inicialmente seleccionado para todas las páginas
  const [specificPages, setSPages] = useState('Todas'); // Valor para enviar a la base de datos
  const [startPage, setStartPage] = useState('');
  const [endPage, setEndPage] = useState('');
  const [individualPages, setIndividualPages] = useState([]); // Lista de páginas específicas
  const [inputPage, setInputPage] = useState(''); // Página que se agregará a la lista
 
  // Manejar el cambio en el tipo de material
  const handleMaterialChange = (e) => {
    const selectedMaterial = e.target.value;
    setMaterial(selectedMaterial);
    
    // Si el material seleccionado es "Fotográfico", establece automáticamente el tamaño a "A4"
    if (selectedMaterial === "Fotográfico") {
      setSize("A4");
    } else {
      // Reinicia el tamaño si se selecciona otro material
      setSize('');
    }
  };


  const handleAddPage = () => {
    const maxPages = numPages;
    console.log(inputPage)
    // Validación para verificar si endPage excede el número de páginas cargadas
    
    if (inputPage > maxPages) {
      setErrorS(`El número de la página final no puede ser mayor que ${maxPages}.`);
      return;
    }
    if (inputPage && !individualPages.includes(inputPage)) {
      setIndividualPages([...individualPages, inputPage]);
      setInputPage(''); // Limpiar el input después de agregar el número
    }
  };

  const handlePageSelection = () => {
    // Número máximo de páginas disponibles (puedes ajustar esto según el contexto de tu aplicación)
    const maxPages = numPages;
  
    // Validación para verificar si endPage excede el número de páginas cargadas
    if ((endPage > maxPages) || (startPage > maxPages)) {
      setErrorS(`El número de la página final no puede ser mayor que ${maxPages}.`);
      return;
    }
  
    // Verificar si se selecciona páginas específicas y si hay un rango o páginas individuales
    if (!allPages) {
      if (startPage && endPage && individualPages.length > 0) {
        setErrorS('No puedes ingresar páginas individuales si ya pusiste rango de páginas a imprimir');
        return;
      }
  
      if ((startPage && endPage) || individualPages.length > 0) {
        // Si hay un rango válido o páginas individuales
        if (startPage && endPage) {
          setSPages(`${startPage}-${endPage}`);
        } else {
          setSPages(individualPages.join(','));
        }
        setErrorS(''); // Limpiar el mensaje de error
      } else {
        setErrorS('Por favor ingresa un rango de páginas o páginas individuales (Usar el botón de + para agregar cada página individual).');
        return;
      }
    } else {
      setSPages('Todas');
    }
  };
  
  const handleClearSelection = () => {
    setStartPage('');
    setEndPage('');
    setIndividualPages([]);
    setSPages('');
    setErrorS(''); // Limpiar cualquier mensaje de error
  };
  
  // Cada vez que numPages cambie, actualiza nHojas
  /*
  useEffect(() => {
    setNumPages(numPages);
    console.log("Valor actualizado de numPages:", numPages);
    console.log("Valor actualizado de numPages:", numPagesF);
  }, [numPages,numPagesF]);*/
/*
  useEffect(() => {
    setNumPagesF(numPages); // Asegura que numPagesF siempre esté sincronizado con numPages
  }, [numPages]);
*/
  useEffect(() => {
    setNumPagesF(numPagesF);
    console.log("numPagesF actualizado:", numPagesF);
    console.log("numPages actualizado:", numPages);
  }, [numPagesF,numPages]);
  useEffect(() => {
    switch (material) {
      case 'Propalcote 200g':
      case 'Propalcote 300g':
        setAvailableSizes(['Carta', 'Oficio', 'Tabloide 32x45']);
        if (size === 'Gran formato 70x100') setSize(''); 
        break;
      case 'Opalina':
        setAvailableSizes(['Carta']);
        if (size === 'Tabloide 32x45') setSize(''); 
        break;
      case 'Fotográfico':
          setAvailableSizes(['A4']);
          if (size ===! 'A4') setSize(''); 
          break;
      case 'Cartulina':
        setAvailableSizes(['Carta','Tabloide 32x45']);
        if (size ===! 'A4') setSize(''); 
        break;
      default:
        setAvailableSizes(['Carta', 'Oficio', 'Tabloide 32x45', 'Gran formato 70x100']);
        break;
    }
  }, [material, size]);
  const handleSubmit = async(e) => {
  //const handleSubmit = (e) => {
    e.preventDefault();
    let numPagesF;

    if (!color || !size || !material || !email) {
      alert('Por favor, completa todos los campos requeridos.');
      return;
    }
    if (specificPages==='Todas'){
      numPagesF=numPages;
    }else{
      numPagesF = await handleInputChange(specificPages);
    }
    console.log("numPagesF",numPagesF)
    console.log("specificPages",specificPages)
  
    if ((allPages===false && !startPage && !endPage && !specificPages) ||(allPages===false && !specificPages) ){
      setErrorP('Por favor, completa los campos requeridos de paginas especificas que deseas imprimir y presiona el boton de confimar selección.');
      return;
    }else {
      setErrorP(''); // Limpiar el error si la condición no se cumple
    }
    
    
    console.log("Valor de numPagesF después de handleInputChange:", numPages);
    setIndividualPages(numPages)
    
     // Asegúrate de que numPagesF está sincronizado con numPages
    setNumPagesF((prevNumPagesF) => (specificPages === 'Todas' ? numPages : prevNumPagesF));
    
    const formData = {  color, size, material,copiesImage,orientation, copies, doubleSided,specificPages,hooped,numPagesF, email, comments,totalP };
    console.log("formData",formData)
    onSubmit(formData);

    // Calcular el total y actualizarlo
    //updateTotal(uploadedFiles.length); // Actualizar total basado en el número de archivos
  };
  const handleAddFileClick = (e, isReplacing = false) => {
    e.preventDefault();
  
    if (isReplacing) {
      console.log("Se va a reemplazar el archivo actual");
      // Aquí no necesitas validación de campos porque vas a reemplazar
      document.getElementById('fileInput').click(); // Abre el input para seleccionar el archivo
    } else {
      // Validación de los campos requeridos
      if (!color || !size || !material || !email) {
        alert('Por favor, completa todos los campos requeridos.');
        return; // Salimos de la función si falta algún campo
      }
      console.log("Se va a agregar otro archivo");
      document.getElementById('fileInput').click(); // Abre el input para seleccionar el archivo
    }
  
    // Captura el evento de cambio del input
    document.getElementById('fileInput').onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        if (isReplacing) {
          handleAddAnotherFileNew(file); // Manejar el archivo como un reemplazo
        } else {
          handleAddAnotherFile(file); // Manejar el archivo como uno nuevo
        }
      }
    };
  };

  const handleAddAnotherFile = async(file) => {
    let numPagesF;
    
    // Verifica que los campos del formulario estén completos
    /*
    if (!color || !size || !material || !email) {
      alert('Por favor, completa todos los campos requeridos.');
      return;
    }*/
    
    if (specificPages==='Todas'){
      numPagesF=numPages;
    }else{
      numPagesF = await handleInputChange(specificPages);
    }
    if ((allPages===false && !startPage && !endPage && !specificPages) ||(allPages===false && !specificPages) ){
      setErrorP('Por favor, completa los campos requeridos de paginas especificas que deseas imprimir y presiona el boton de confimar selección.');
      return;
    }else {
      setErrorP(''); // Limpiar el error si la condición no se cumple
    }
    
     // Asegúrate de que numPagesF está sincronizado con numPages
    setNumPagesF((prevNumPagesF) => (specificPages === 'Todas' ? numPages : prevNumPagesF));
    setEmail((prevUser) => prevUser);
  
    const formData = { color, size, material,copiesImage,orientation, copies, doubleSided,specificPages,hooped,numPagesF,email, comments,totalP };
    console.log("formData",formData)
   
    //console.log("formData another print",formData)
    // Reinicia los campos del formulario
    setColor('');
    setSize('');
    setMaterial('');
    setCopies(1);
    setCopiesImage('1');
    setOrientation('Vertical')
    setSPages('Todas');
    setDoubleSided(false);
    setHooped(false);
    setNumPagesF(numPages);
    setComments('');
    setTotalP(0);
    setStartPage('');
    setEndPage('');
    setIndividualPages([]); // Lista de páginas específicas
    setInputPage('');
    setAllPages(true)
    onAddAnotherFile(file,formData); // Pasa el archivo junto con los datos del formulario
  };
 

  const handleAddAnotherFileNew = async(file) => {
    
    console.log("Se eliminó archivo anterior")
    console.log("uploadedFiles",uploadedFiles)
    if(uploadedFiles.length>1){
      // Eliminar el archivo actual y cargar el anterior
      const newUpdatedFiles = uploadedFiles.slice(0, -1);
      
      console.log("newUpdatedFiles-1",newUpdatedFiles)
      //console.log("newUpdatedFilesInfo-1",newUpdatedFilesInfo)
      setUploadedFiles(newUpdatedFiles);
      //setUploadedFilesInfo(newUpdatedFilesInfo); // Actualizar detalles
    }else{
      localStorage.removeItem('uploadedFiles');
      localStorage.removeItem('uploadedFilesInfo');
      setUploadedFiles([]);
      setUploadedFilesInfo([]); // Actualizar detalles
      
    }
    handleFileUpload(file);
    
    /*
    
    if (specificPages==='Todas'){
      numPagesF=numPages;
    }else{
      numPagesF = await handleInputChange(specificPages);
    }
    if ((allPages===false && !startPage && !endPage && !specificPages) ||(allPages===false && !specificPages) ){
      setErrorP('Por favor, completa los campos requeridos de paginas especificas que deseas imprimir y presiona el boton de confimar selección.');
      return;
    }else {
      setErrorP(''); // Limpiar el error si la condición no se cumple
    }
    
     // Asegúrate de que numPagesF está sincronizado con numPages
    setNumPagesF((prevNumPagesF) => (specificPages === 'Todas' ? numPages : prevNumPagesF));
    setUser((prevUser) => prevUser);
  
    const formData = { color, size, material,copiesImage, copies, doubleSided,specificPages,hooped,numPagesF,user, comments,totalP };
    console.log("formData",formData)
    */
    //console.log("formData another print",formData)
    // Reinicia los campos del formulario
    setColor('');
    setSize('');
    setMaterial('');
    setCopies(1);
    setCopiesImage('1');
    setOrientation('Vertical')
    setSPages('Todas');
    setDoubleSided(false);
    setHooped(false);
    setNumPagesF(numPages);
    setComments('');
    setTotalP(0);
    setStartPage('');
    setEndPage('');
    setIndividualPages([]); // Lista de páginas específicas
    setInputPage('');
    setAllPages(true)
    //onAddAnotherFile(file,formData); // Pasa el archivo junto con los datos del formulario
  };
  const handleDoubleSidedChange = (e) => {
    const isChecked = e.target.checked;
    // Verificar si se selecciona doble cara y el tipo de papel es fotográfico
    if (isChecked && material === 'Fotográfico') {
      setErrorDC('No se puede seleccionar doble cara con papel fotográfico.');
      setDoubleSided(false); // Desmarcar el checkbox
    } else {
      setErrorDC(''); // Limpiar el error si la condición no se cumple
      setDoubleSided(isChecked);
    }
  };
  async function handleInputChange(value) {
  //const handleInputChange = (value) => {
    
    //const value = e.target.value;

    console.log(value)
    // Validar si solo contiene números, guiones o comas
    const isValid = /^(\d+(-\d+)?(,\d+(-\d+)?)*$)?/.test(value);
    
    console.log("isValid",isValid)
    /*
    
    if (!isValid) {
      setError('Formato inválido. Usa solo números, guiones (-) y comas (,).');
      return;
    }*/
    if (value==='Todas'){
      setNumPagesF(numPages); // Actualizar numPagesF con el total calculado
      setSPages('Todas');
      return;
    }
    /*
    // Verificar si hay un punto o letras en el valor ingresado
    if (/[a-zA-Z.]/.test(value)) {
      setNumPagesF(numPages); // Si hay un punto o letras, actualizar numPagesF con numPages
      setError('Formato inválido. Usa solo números, guiones (-) y comas (,).');
      return; // Salir de la función después de esta actualización
    }*/
  
    // Extraer números y verificar límites
    const pageNumbers = [];
    const ranges = value.split(',');
    for (const range of ranges) {
      if (range.includes('-')) {
          console.log('range',range)
          // Si es un rango, separar y obtener los límites
          const [start, end] = range.split('-').map(Number);

          // Validar límites
          if (end > numPages) {
              setErrorS(`Error: el valor máximo permitido es ${numPages}.`);
              return;
          }

          // Añadir los números dentro del rango
          for (let i = start; i <= end; i++) {
              pageNumbers.push(i);
          }
      } else {
          const page = Number(range);
          console.log("page",page)
          if (page > numPages) {
              setErrorS(`Error: el valor máximo permitido es ${numPages}.`);
              return;
          }
          pageNumbers.push(page);
          console.log("pageNumbers",pageNumbers)
          
          
      }
    }
    console.log("pageNumbers",pageNumbers)
    // Actualiza el número de páginas basado en el contenido único de pageNumbers
    const uniquePages = [...new Set(pageNumbers)]; // Eliminar duplicados
    console.log("uniquePages.length",uniquePages.length)
    const totalPages = uniquePages.length; // Contar páginas únicas
    console.log("totalPages")
    setNumPagesF(totalPages); // Actualizar numPagesF con el total calculado
    //setNumPagesF(pageNumbers); // Actualizar numPagesF con el total calculado
    //setSPages(value);
    //setError(''); // Limpiar error
    return totalPages;
  };


  return (
    <div className='print-form'>
           
      <form onSubmit={handleSubmit}>
        <label>
          Correo electrónico:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onFocus={() => setEmail('')} 
            autoComplete="email"
            required
          />
        </label>
        <label>
          Blanco y negro | Color:
          <div className="select-container">
            <select value={color} onChange={(e) => setColor(e.target.value)} required>
              <option value="">Seleccionar opción</option>
              <option value="Blanco y negro">Blanco y negro</option>
              <option value="Color">Color</option>
            </select>
          </div>
        </label>
        <label>
          Tipo de papel:
          <div className="select-container">
            {/* <select value={material} onChange={(e) => setMaterial(e.target.value)} required> */}
            <select value={material} onChange={handleMaterialChange} required>
              <option value="">Seleccionar material</option>
              <option value="Papel normal">Papel normal</option>
              <option value="Cartulina">Cartulina</option>
              <option value="Propalcote 200g">Propalcote 200g</option>
              <option value="Propalcote 300g">Propalcote 300g</option>
              <option value="Opalina">Opalina</option>
              <option value="Fotográfico">Fotográfico</option>
            </select>
          </div>
        </label>
        <label>
          Tamaño:
          <div className="select-container">
            <select value={size} onChange={(e) => setSize(e.target.value)} required>
              <option value="">Seleccionar tamaño</option>
              {availableSizes.map((sizeOption) => (
                <option key={sizeOption} value={sizeOption}>
                  {sizeOption}
                </option>
              ))}
            </select>
          </div>
        </label>
        {material === "Fotográfico" && (
        <p>Si deseas imprimir la misma foto varias veces en la misma hoja
        debes de diseñarlo antes de enviar a imprimir</p>

/*
        <label>
          Seleccionar número de copias de la imagen por hoja:
          <div className="select-container">
          <select value={copiesImage} onChange={(e) => setCopiesImage(e.target.value)} required>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="4">4</option>
            <option value="6">6</option>
          </select>
          </div>
          <div className="image-container">
            {(copiesImage === "2" || copiesImage === "4"|| copiesImage === "6") && (
              <div style={{ textAlign: 'center' }}>
                <p>Previsualización ejemplo de impresion:</p>
              </div>
            )}
              {copiesImage === "2" && <img src="/2Copias.webp" alt="Imagen para 2 copias" />}
              {copiesImage === "4" && <img src="/4Copias.webp" alt="Imagen para 4 copias" />}
              {copiesImage === "6" && <img src="/6Copias.webp" alt="Imagen para 6 copias" />}
            </div>
          </label>
*/

          
        )}
        <label>
          Seleccionar orientación:
          <div className="select-container">
          <select value={orientation} onChange={(e) => setOrientation(e.target.value)} required>
            <option value="Vertical">Vertical</option>
            <option value="Horizontal">Horizontal</option>
          </select>
          </div>
          <div className="image-container">
            {(orientation === "Vertical" || orientation === "Horizontal") && (
              <div style={{ textAlign: 'center' }}>
                <p>Previsualización ejemplo de impresion:</p>
              </div>
            )}
              {orientation === "Vertical" && <img src="/vertical.webp" alt="Vertical" />}
              {orientation === "Horizontal" && <img src="/horizontal.webp" alt="Horizontal" />}
            </div>
          </label>
        <label>
          # Copias:
          <input
            type="number"
            value={copies}
            min={1}
            onChange={(e) => setCopies(Number(e.target.value))}
            onFocus={() => setCopies('')} 
            required
          />
        </label>
        <label>
          Doble-cara:
          <input
            type="checkbox"
            checked={doubleSided}
            onChange={handleDoubleSidedChange}

          />
        </label>
        {errorDC && <p style={{ color: 'red' }}>{errorDC}</p>} {/* Mostrar mensaje de error */}
        <label>
          Argollado:
          <input
            type="checkbox"
            checked={hooped}
            onChange={(e) => setHooped(e.target.checked)}
          />
        </label>
        <div className="pages-selection-ppal">
          <h3>¿Deseas imprimir todas las páginas?</h3>
          <div className="pages-selection">
            <label>
              <input
                type="radio"
                checked={allPages}
                onChange={() => {
                  setAllPages(true);
                  setSPages('Todas');
                }}
              />
              <p>Sí, imprimir todas las páginas</p>
            </label>
            <br />
            <label>
              <input
                type="radio"
                checked={!allPages}
                onChange={() => {
                  setAllPages(false);
                  setSPages(''); // Limpiar valor de páginas específicas
                }}
              />
              <p>No, seleccionar páginas específicas</p>
            </label>

          </div>
         

          {!allPages && (
            <div>
            <div className="specific-pages">
              <div className="specific-pages-info">
                <h3>Seleccionar rango de páginas a imprimir:</h3>
                <div className='specific-pages-fromTo'>
                  <label>
                    <p>Desde: </p>
                    <input
                      type="number"
                      value={startPage}
                      onChange={(e) => setStartPage(e.target.value)}
                    />
                  </label>
                  <label>
                    <p>Hasta: </p>
                    <input
                      type="number"
                      value={endPage}
                      onChange={(e) => setEndPage(e.target.value)}
                    />
                  </label>
                </div>
              </div>
              <div className='specific-pages-o'>
                <h3>Ó</h3>
              </div>
              <div className='individual-pages'>
                <h3>Ingresa páginas individuales:</h3>
                <div className='individual-pages-info'>
                    <input
                      type="number"
                      value={inputPage}
                      onChange={(e) => setInputPage(e.target.value)}
                    />
                    <button className='button3' type="button" title="Agrega una página específica"
                       onClick={() => {
                        handleAddPage();
                    }}>
                      +
                    </button>
                </div>
                <div className='selected-pages'>
                  <p> Páginas seleccionadas: {individualPages.join(', ')}</p>
                </div>
              </div>
 
            </div>
            <div className='buttonSelected'>
              <button className='buttonConfirm' type="button" onClick={handlePageSelection}>
                Confirmar selección
              </button>
              <button className='buttonEliminar' type="button" onClick={handleClearSelection}>
                Eliminar selección
              </button>
            </div>
            
            {errorS && <p style={{ color: 'red' }}>{errorS}</p>}
            
          </div>
            
      )}
       
        <div className='print-pages'>
          <p><strong>Páginas a imprimir:</strong> {specificPages}</p>
        </div>
      </div>
       
        {errorP && <p style={{ color: 'red' }}>{errorP}</p>}
        <label>
          Comentarios:
          <input
            type="text"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            onFocus={() => setComments('')} 
          />
        </label>
        
        <div className='Buttons'>
          <button className='button' type="submit" >Proceder al pago</button>        
          <button className='button' type="button" onClick={(e) => handleAddFileClick(e)}>Guardar y agregar otro archivo</button>
            <input 
              type="file" 
              name="file" 
              id="fileInput" 
              style={{ display: 'none' }}  
            />

            <button className='button' type="button" onClick={(e) => handleAddFileClick(e, true)}>Eliminar archivo actual</button>
        </div>        
      </form>
    </div>
  );
}

export default PrintForm;

