// src/pages/PaymentPage.js

import React, { useState, useEffect,useRef } from 'react';
import logo from '../utils/logo.webp'; 
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { bancolombiaConfig } from '../config';
/*
let bancolombiaConfig;
if (process.env.NODE_ENV === 'development') {
  bancolombiaConfig = require('../config');
}*/

/*const bancolombiaConfig = {
  data: JSON.parse(process.env.REACT_APP_BANCOLOMBIA_DATA || '{}'),
  headers: JSON.parse(process.env.REACT_APP_BANCOLOMBIA_HEADERS || '{}')
};*/



function PaymentPage({ 
  total,
  setTotal,
  uploadedFiles,
  setUploadedFiles,
  formData,
  uploadedFilesInfo,
  setUploadedFilesInfo,
  deleteRow,
  updateTotal
}) {
  // Ejecuta handleLoadQR al cargar la página
  const [qrImage, setQrImage] = useState(null);
  const navigate = useNavigate();
  const qrRef = useRef(null);
  const [pngUrl, setPngUrl] = useState(null);
  const [qrError, setQrError] = useState(null);

  
  console.log("user",formData.email)
  console.log("uploadedFilesInfo",uploadedFilesInfo)
 
  // Función para realizar el POST periódicamente
  const APIBancolombia = async () => {
    axios.get('https://www.megapapelapp.com/pay')
      .then(response => {
        if (!response.data.success) {
          console.log(response.data.message); // 'No se ha recibido ningún token aún.'
        } else {
          // Maneja el token como desees
          console.log('Token recibido:', response.data.token);
        }
      })
      .catch(error => {
        console.error('Error en GET:', error);
      });
  };
  

  const handleLocalPayment = async () => {

    await axios.get('https://databasemegapapel-production.up.railway.app/api/update-state-docs', {
        params: {
          state:"Pago local",// Pasar idDocs como parámetro,
          employee:"Sin asignar",
          email:formData.email
        }
      });
    localStorage.removeItem('uploadedFiles');
    setUploadedFiles([]); 
    localStorage.removeItem('uploadedFilesInfo');
    setUploadedFilesInfo([]); 
    navigate('/local-payment-info');
  
  };
  const handleNavigateHome = () => {
    localStorage.removeItem('uploadedFiles');
    setUploadedFiles([]); 
    localStorage.removeItem('uploadedFilesInfo');
    setUploadedFilesInfo([]); 
    navigate('/'); // Navega a la página de inicio
  };

  
  useEffect(() => {
    console.log("uploadedFilesInfo",uploadedFilesInfo)
    const fetchQRCode = async (totalAmount) => {
      
      try {
        console.log(bancolombiaConfig.data)
        console.log(bancolombiaConfig.headers)
        const responseQR = await axios.post('https://gw-medios-de-pago.apps.bancolombia.com/public-partner/production/v2/sales-service/cross-channel/transaction-authorization/qr-codes', bancolombiaConfig.data, {
          headers: bancolombiaConfig.headers,
        });
        const txtQR = responseQR.data.data.qrImage; // Aquí obtienes el QR en formato base64
        // Codificas la imagen en UTF-8
        const base64Img = txtQR; // Si ya es base64, no necesitas hacer nada adicional
        const utf8Encoded = base64Img; // .encode('utf-8') no es necesario en JavaScript
        
        // Almacenas el string base64 en el estado
        setQrImage(utf8Encoded);
        setQrError(null); 
    
      } catch (error) {
        console.error('Error al obtener el QR:', error);
        setQrError('No se pudo hacer la solicitud del QR. Vuelva a recargar la página o pague en local.');
      }
    };
    if (total) {
      fetchQRCode(total); // Llamada con el valor de total
    }
    // Intervalo para ejecutar la API cada 10 segundos
    const interval = setInterval(() => {
      APIBancolombia(); // Llamar a la función periódicamente
    }, 10000);

    // Timeout para detener el intervalo después de 2 minutos (120,000 milisegundos)
    const timeout = setTimeout(async() => {
      clearInterval(interval); // Detener el intervalo
      localStorage.removeItem('uploadedFiles');
      setUploadedFiles([]); 
      localStorage.removeItem('uploadedFilesInfo');
      setUploadedFilesInfo([]); 
      await axios.get('https://databasemegapapel-production.up.railway.app/api/update-state-docs', {
        params: {
          state:"Sin procedimiento elegido",// Pasar idDocs como parámetro,
          employee:"Sin asignar",
          email:formData.email
        }
      });
      navigate('/'); // Redirigir a la página principal
    }, 120000);

    // Limpiar intervalos y timeouts cuando el componente se desmonte
    return () => {
      clearInterval(interval); // Detener el intervalo si el componente se desmonta
      clearTimeout(timeout); // Limpiar el timeout
    };
  }, [navigate,setUploadedFiles,uploadedFilesInfo,setUploadedFilesInfo,total,formData.email]);
 /*
  const handleDownloadQR2 = () => {
    const qrElement = qrRef.current;
    if (qrElement) {
      setTimeout(() => {
        toPng(qrElement, { bgcolor: '#fff' })
          .then((dataUrl) => {
            const link = document.createElement('a');
            link.download = 'qr-code.png';
            link.href = dataUrl;
            link.click();
          })
          .catch((error) => {
            console.error('Error al generar la imagen:', error);
          });
      }, 300); // Retraso para asegurar el renderizado
    }
  };*/
  const handleDownloadQR = () => {
    if (!pngUrl) return; // Asegurarse de que existe el PNG URL
  
    // Crear un enlace temporal para la descarga
    const link = document.createElement('a');
    link.href = pngUrl;
    link.download = 'qr-code.png'; // Nombre del archivo descargado
  
    // Simula un clic para iniciar la descarga
    link.click();
  
    // Limpieza: eliminar el enlace temporal
    link.remove();
  };

  
  useEffect(() => {
    if (qrImage) {
      // Crear una imagen desde el base64 SVG
      const svgImage = new Image();
      svgImage.src = `data:image/svg+xml;base64,${qrImage}`;
      
      svgImage.onload = () => {
        // Definir el factor de escala (ej. 3x para mejorar la calidad)
        const scale = 3;

        // Obtener las dimensiones reales de la imagen SVG
        const width = svgImage.naturalWidth;
        const height = svgImage.naturalHeight;

        // Crear un canvas con tamaño ajustado
        const canvas = document.createElement('canvas');
        canvas.width = width * scale;
        canvas.height = height * scale;

        const ctx = canvas.getContext('2d');

        // Aumentar la resolución al escalar el contenido
        ctx.scale(scale, scale);
        ctx.drawImage(svgImage, 0, 0, width, height);

        // Convertir canvas a PNG en base64
        const pngBase64 = canvas.toDataURL('image/png');
        setPngUrl(pngBase64); // Guardar la URL PNG en el estado
      };
    }
  }, [qrImage]);
  // Función para calcular el total basado en los archivos
  const calculateTotal = (filesInfo) => {
    return filesInfo.reduce((acc, file) => acc + file.totalP, 0);
  };

  // Calcular el total cada vez que cambie uploadedFilesInfo
  useEffect(() => {
    setTotal(calculateTotal(uploadedFilesInfo));
  }, [uploadedFilesInfo,setTotal]);

   // Función para eliminar un archivo específico
  const handleRemoveFile = async (index) => {
    console.log("Eliminar archivo antes de pagar");
    console.log("uploadedFilesInfo", uploadedFilesInfo);

    // Obtener el idDocs del archivo a eliminar
    console.log("index",index)
    const email = uploadedFilesInfo[index]?.email;  // Asegúrate de que `idDocs` exista en tus datos
    const idDocs = uploadedFilesInfo[index]?.idDocs;  // Asegúrate de que `idDocs` exista en tus datos
    console.log("email")
    // Eliminar el archivo en el índice especificado de la lista local
    const newUploadedFilesInfo = uploadedFilesInfo.filter((_, i) => i !== index);
    setUploadedFilesInfo(newUploadedFilesInfo);
    setTotal(calculateTotal(newUploadedFilesInfo));
    deleteRow(email,index,idDocs)
    updateTotal(calculateTotal(newUploadedFilesInfo),email)

  };

  return (
    <div className="payment-page">
      <button className='button'  onClick={handleNavigateHome}>Inicio</button>
      <div className="payment-page-info">
        
        <img className="logo" src={logo} alt="Logo"  />
        {uploadedFilesInfo.length > 0 && (
        <div className='resumen'>
          <h4>Archivos subidos:</h4>
          <div className='resumen-grid'>
            {uploadedFilesInfo.map((fileInfo,index) => (
              <div className='resumenInfo' key={fileInfo.id || index}>
                <div className="file-info-row">
                  <p><strong className="darker-text">Nombre</strong>: {fileInfo.name}</p>
                  <p><strong className="darker-text">Opción de impresión</strong>: {fileInfo.color}</p>
                  <p><strong className="darker-text">Material</strong>: {fileInfo.material}</p>
                  {fileInfo.material === "Fotográfico" && (
                    <p><strong className="darker-text"># Copias de imagenes</strong>: {fileInfo.copiesImage}</p>
                  )}
                  <p><strong className="darker-text">Tamaño</strong>: {fileInfo.size}</p>
                  <p><strong className="darker-text"># Copias</strong>: {fileInfo.copies}</p>
                  <p><strong className="darker-text">Doble cara</strong>: {fileInfo.sides}</p>
                  <p><strong className="darker-text">Argollado</strong>: {fileInfo.hooped}</p>
                  <p><strong className="darker-text">#Páginas del documento</strong>: {fileInfo.numPagesF}</p>
                  <p><strong className="darker-text">Comentarios</strong>: {fileInfo.comments}</p>
                  <p><strong className="darker-text">Total</strong>: {fileInfo.totalP}</p> 
                  {uploadedFilesInfo.length > 1 && (
                  <button className="delete-button" onClick={() => handleRemoveFile(index)}>Eliminar archivo</button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

        <h2>Total a pagar: ${total}</h2>
        {/*qrImage ? (
          <div className="qr" ref={qrRef}>
            <img src={`data:image/svg+xml;base64,${qrImage}`} alt="QR Code" />
            <button className='download-btn' onClick={handleDownloadQR}>
              Descargar QR
            </button>
          </div>
        ) : qrError ? (
          <p style={{ color: 'red' }}>{qrError}</p>
        ) : (
          <h1>Cargando QR...</h1>
        )*/}
        {/*<p>Si deseas que imprimamos tu pedido de inmediato, puedes realizar el pago a través de un código
           QR de Bancolombia. <br></br> Alternativamente, puedes presionar el botón "Pagar en local" para pagar en efectivo,
           y procederemos con la impresión una vez hayas realizado el pago en nuestra tienda.
      </p>*/}
        <button className='button' type="button" onClick={handleLocalPayment}>Pagar en local</button>
      </div>
     
    </div>
  );
}

export default PaymentPage;