// src/pages/PdfSelectPage.js

import React, { useState, useEffect } from 'react';
import PdfViewer from '../components/PdfViewer';
import PrintForm from '../components/PrintForm';
import logo from '../utils/logo.webp'; 
import { useNavigate } from 'react-router-dom';


function PdfSelectPage({ selectedPdf,onSubmitPrintForm,onNumPagesChange,uploadedFiles,setUploadedFiles,uploadedFilesInfo,setUploadedFilesInfo, onAddAnotherFile, numPages,setNumPages,file,handleFileUpload }) {
  const navigate = useNavigate(); // Usar useNavigate aquí
  const [loading, setLoading] = useState(false);
  
  console.log("uploadedFilesInfo",uploadedFilesInfo)
  useEffect(() => {
    window.scrollTo(0, 0); // Mueve el scroll hacia la parte superior
  }, []); // [] asegura que se ejecute solo cuando el componente se monta

  // Función para simular una operación que desencadena el estado de carga
  const handleFormSubmit = async (form) => {
    setLoading(true); // Activar modo de carga
    await onSubmitPrintForm(form); // Llamar a la función de envío
    setLoading(false); // Desactivar modo de carga
  };
  // Esta función será llamada desde PdfViewer
  const handleNumPagesChange = (pages) => {
    setNumPages(pages);  // Actualiza el número de páginas cuando se obtiene en PdfViewer
  };

  const handleNavigateHome = () => {
    localStorage.removeItem('uploadedFiles');
    setUploadedFiles([]); 
    localStorage.removeItem('uploadedFilesInfo');
    setUploadedFilesInfo([]); 
    navigate('/'); // Navega a la página de inicio
  };

  // Función para agregar otro archivo
  const handleAddAnotherFile = (file,form) => {
    
    if (file) {
      onAddAnotherFile(file,form);
      window.scrollTo(0, 0);
      console.log("uploadedFiles updated in pdfSelect", [...uploadedFiles, file]);
    }

  };
 
 

  return (
    <div className='pdfSelect'>
      <div className='pdfSelect-button'>
        <button className='button' onClick={handleNavigateHome}>Inicio</button>
      </div>
      <div className='pdfSelect-container'>
        <img className="logo" src={logo} alt="Logo" />
        {loading && (
          <div className="loading-overlay">
            <p>Cargando... Por favor, espera.</p>
          </div>
        )}

        {!loading && (
          <div className='pdfSelect-info'> 
            <div className="pdf-select-page">
              {loading ? (
              <div className="loading">
                <p>Cargando archivos...</p>
              </div>
            ) : (
              uploadedFiles.length > 0 && (
                <div className='archivos-subidos'>
                  <h4>Archivos subidos:</h4>
                  {uploadedFiles.map((file, index) => (
                    <div key={index}>
                      <p>{file.name}</p>
                    </div>
                  ))}
                </div>
              )
            )}
              <PdfViewer selectedPdf={selectedPdf} onNumPagesChange={handleNumPagesChange} file={file}  />
            </div>
            <PrintForm onSubmit={handleFormSubmit}
              onAddAnotherFile={handleAddAnotherFile}
              numPages={numPages}
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles} 
              uploadedFilesInfo={uploadedFilesInfo}
              setUploadedFilesInfo={setUploadedFilesInfo} 
              handleFileUpload={handleFileUpload}
              />
          </div>
        )}

      </div>
     
    </div>
    
  );
}

export default PdfSelectPage;
