import React, { useState } from 'react';
import FileUpload from '../components/FileUpload';
import { useNavigate } from 'react-router-dom';
import logo from '../utils/logo.webp'; 
import { Link } from 'react-router-dom';



function HomePage({ onFileUpload }) {
  const [error, setError] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const navigate = useNavigate(); // Usar useNavigate aquí


  const handleFileUpload = (file) => {
    if (file) {
      onFileUpload(file);  // Llamar a la función de App.js para actualizar los archivos
      setError(null);
      console.log("file name home",file.name)
      // Actualiza el estado para agregar el nuevo archivo sin sobrescribir los anteriores
      // Aquí puedes agregar el nuevo archivo directamente al estado
      setUploadedFiles((prevFiles) => [...prevFiles, file.name]);
      console.log("uploadedFiles home final", [...uploadedFiles, file.name]);
      navigate('/pdf-select');  // Navegar a la página de selección de PDF
    } 
  
    // Maneja la carga del archivo aquí
    //console.log('Archivo cargado:', file);
  
  };

  const handleError = (errorMessage) => {
    setError(errorMessage);
  };
 
  return (
    <div className="home-page">
      {/* <EmailInput email={email} setEmail={setEmail} onEmailSubmit={handleEmailSubmit} /> */} 
      
      <div className='home'>
        <img className="logo" src={logo} alt="Logo" /> 
        <div className='info'>
          <div className='texto'>
            <h1>Imprime con Nosotros</h1>
            <h2>Fácil y Rápido</h2>
            <h3>Sube tus archivos, paga en línea y recoge en el punto físico. ¡Así de simple!</h3>
          </div>
          <div className='buttonSelect'>
            <h2>Archivos permitidos:</h2>
            <h3>PDF, PNG JPEG y JPG</h3>
              {/* Mostrar el título solo si hay archivos subidos */}
              {error && <p className="error-message">{error}</p>}
              <FileUpload onFileUpload={handleFileUpload} onError={handleError} />
          </div>
            <Link className="prices-button" to="/prices">
              Ver precios
            </Link>
          
      
      </div>
        
        
    </div>
      

    </div>
  );
}

export default HomePage;
