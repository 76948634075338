import React, { useState,useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import PdfSelectPage from './pages/PdfSelectPage';
import PaymentPage from './pages/PaymentPage';
import LocalPaymentInfo from './pages/LocalPayment';
import QRPaymentInfo from './pages/QRPayment';
import Error from './pages/Error';
import '../src/styles/styles.css'; 
import axios from 'axios';
import moment from 'moment-timezone';
import PricesPage from './pages/Precios'; // Página de precios
import { pdfjs } from 'react-pdf';


const CURRENT_VERSION = '2.6.0'; 


// Función que calcula el total según condiciones de precios
async function calculateTotal(formData) {
  try {
    //Request a base de datos para consultar los precios
    const response = await axios.get('https://databasemegapapel-production.up.railway.app/api/prices');
    const pricesData = response.data;
    const prices = {};
    pricesData.forEach(price => {
      const { material, size, color, sides, total } = price;
      prices[material] = prices[material] || {};
      prices[material][size] = prices[material][size] || {};
      prices[material][size][color] = prices[material][size][color] || {};
      prices[material][size][color][sides] = total;
    });
    
    
    const { material, size, color, copies, doubleSided, numPagesF,hooped } = formData;
    const sides = doubleSided ? 'Doble cara' : 'Una cara';
    let priceHooped=0
    if (hooped) {
      if (numPagesF > 200) {
        priceHooped = 8000;
      } else if (numPagesF > 150 && numPagesF <= 200) {
        priceHooped = 6000;
      } else if (numPagesF > 100 && numPagesF <= 150) {
        priceHooped = 5000;
      } else if ( numPagesF <= 100) {
        priceHooped = 3000;
      }
    }
    
    if (!prices[material] || !prices[material][size] || !prices[material][size][color] || !prices[material][size][color][sides]) {
      console.log('Material, tamaño, color o tipo de impresión no válidos');
      return 'Material, tamaño, color o tipo de impresión no válidos';
    }

    const pricePerSide = prices[material][size][color][sides];
    let price;
    if (doubleSided) {
      /*impar*/
      if (numPagesF % 2 !== 0) {
        if(numPagesF<2){
          price = (prices[material][size][color]['Una cara']+priceHooped)*copies;
          
        }else{
          price = ((((numPagesF-1) * pricePerSide)+prices[material][size][color]['Una cara'])+priceHooped)*copies;
        }
        
      }else{
        /*par*/
        price = ((numPagesF * pricePerSide)+priceHooped)* copies;
      }
    } else {
      price = ((numPagesF * pricePerSide)+priceHooped)* copies;
    }
    console.log(price)

    return price;
  } catch (error) {
    console.error('Error al calcular el total:', error);
    //return 'Error al calcular el total'; // Devuelve un valor por defecto o maneja el error de otra manera
  }
}


function App() {
  const [isLoading, setIsLoading] = useState(false); 
  const [isLoadingPages, setIsLoadingPages] = useState(true); 
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [file, setFile] = useState(null);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate(); // Correcto aquí
  const [numPages, setNumPages] = useState(0);
  const [filename, setFilename] = useState(''); 
  const [add, setAdd] = useState(0); 
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFilesInfo, setUploadedFilesInfo] = useState([]);
  const [formData, setFormData] = useState({
    color: '',
    size: '',
    material: '',
    copiesImage:'1',
    orientation:'Vertical',
    copies: 0,
    numPagesF:numPages, 
    doubleSided: false,
    hooped:false,
    email:'',
    comments:'',
    totalP:0,
    allPages:true,
    specificPages:'Todas',
    startPage:'',
    endPage:'',
    individualPages:[],
    inputPage:''

  });
  console.log("Version",CURRENT_VERSION)
  let idCounter = 0;
  const checkForUpdates = async () => {
    try {
      const response = await fetch('/version.json');
      if (response.ok) {
        const { version } = await response.json();
        if (version !== CURRENT_VERSION) {
          alert('Nueva versión disponible. La página se recargará.');
          window.location.reload(true); // Forzar recarga
        }
      }
    } catch (error) {
      console.error('Error al verificar la versión:', error);
    }
  };

  useEffect(() => {
    checkForUpdates();
  }, []);
  useEffect(() => {
    const handleBackButton = () => {
      localStorage.removeItem('uploadedFiles');
      setUploadedFiles([]); 
      localStorage.removeItem('uploadedFilesInfo');
      setUploadedFilesInfo([]); 
    };
  
    window.addEventListener('popstate', handleBackButton);
  
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);
   // Funcion para eliminar fila en prints
   const deleteRow = async (email,index,idDocs) => {
    
    try {
      await axios.delete(`https://databasemegapapel-production.up.railway.app/api-prints/delete-row/${email}/${index}/${idDocs}`);
      console.log(`Fila con id ${index} eliminada exitosamente`);
    } catch (error) {
      console.error(`Error al eliminar fila con id ${index}:`, error);
    }
  };

  // Función para actualizar el número de páginas ??
  const handleNumPagesChange = (pages) => {
    setNumPages(pages);

  };
  
  const addRowToDatabasePrints = async (doc,currentFormData,idDocsMax) => {
    
    if (
      
      doc.fileId &&
      currentFormData.size &&
      currentFormData.material &&
      currentFormData.color &&
      currentFormData.copies &&
      currentFormData.orientation &&
      currentFormData.numPagesF &&
      !isNaN(Math.ceil(currentFormData.numPagesF / (currentFormData.doubleSided ? 2 : 1))) &&
      await calculateTotal(currentFormData) !== undefined // Asegúrate de que el total no sea undefined

    ) {
      
    
      const total = await calculateTotal(currentFormData);
      
      const sheetData = {
        idDocs:idDocsMax,
        doc: `https://drive.google.com/file/d/${doc.fileId}/view`, 
        filename:filename,
        size: currentFormData.size,
        material: currentFormData.material,
        copiesImage:currentFormData.copiesImage,
        orientation:currentFormData.orientation,
        color: currentFormData.color,
        copies: currentFormData.copies,
        sides: currentFormData.doubleSided ? 'Doble cara' : 'Una cara',
        pages: currentFormData.numPagesF,
        specificPages:currentFormData.specificPages,
        hooped:currentFormData.hooped,
        nHojas: currentFormData.doubleSided? (currentFormData.numPagesF % 2 === 1 ? (Math.floor(currentFormData.numPagesF / 2) + 1) * currentFormData.copies : (currentFormData.numPagesF / 2) * currentFormData.copies): currentFormData.numPagesF * currentFormData.copies,
        comments:currentFormData.comments,
        total: total
      };
   
      
      try{
        console.time("Execution Time");
        await axios.post('https://databasemegapapel-production.up.railway.app/api-prints/add-row', 
        sheetData);
        console.log("Fila prints agregada a la base de datos exitosamente", sheetData);
        console.timeEnd("Execution Time");
      } catch (error) {
        console.error('Error al agregar fila a la base de datos:', error);
      }
    } else {
      console.error('Datos inválidos para enviar a la base de datos');
      localStorage.removeItem('uploadedFiles');
      setUploadedFiles([]); 
      localStorage.removeItem('uploadedFilesInfo');
      setUploadedFilesInfo([]); 
      navigate('/error');
    }
  
  
};
const addRowToDatabaseDocs = async (totalFinal,email) => {


    const sheetDataDocs = {
      date: moment().tz('America/Bogota').format('YYYY-MM-DD HH:mm:ss'),
      email:email,
      employee: "",
      total: totalFinal,
      state: ""
    };

    
    try {
      console.time("Execution Time"); 
      await axios.post('https://databasemegapapel-production.up.railway.app/api-docs/add-row', sheetDataDocs);
      console.log("Fila docs agregada a la base de datos exitosamente", sheetDataDocs);
      console.timeEnd("Execution Time");
    } catch (error) {
      console.error('Error al agregar fila a la base de datos:', error);
    }

};
 

 // Función para subir el archivo a Google Drive
 const uploadPdfToServer = async (file,currentFormData, idDocsMax) => {

  try {
    console.time("Execution Time"); 
    
    const response = await fetch(selectedPdf);
    const blob = await response.blob();
    const formDataFile = new FormData();
    formDataFile.append('file', blob, filename); // Asegúrate de que 'file' coincide con el campo de formulario en el backend
    const uploadResponse = await axios.post('https://backendgoogle-production.up.railway.app/api/upload', formDataFile);

     // Verificar si la respuesta del backend es válida
    if (!uploadResponse.data) {
      throw new Error('La respuesta del servidor no contiene el enlace esperado.');

    }
    await addRowToDatabasePrints(uploadResponse.data,currentFormData,idDocsMax); // Suponiendo que el response.data tenga el link de Google Drive
    console.timeEnd("Execution Time");
    
  } catch (error) {
    alert(`Hubo un error al procesar el archivo: ${error.message}`);
    console.error('Error uploading PDF:', error);
    // Detener ejecución del código aquí
    localStorage.removeItem('uploadedFiles');
    setUploadedFiles([]); 
    localStorage.removeItem('uploadedFilesInfo');
    setUploadedFilesInfo([]); 
    throw error;
  }
    
};

  const handleFileUpload = (file) => {
    console.time("Execution Time"); 

    setIsLoading(true); // Inicia la carga
    setIsLoadingPages(true);

    // Si es un archivo PDF, obtener el número de páginas
    if (file && file.type === 'application/pdf') {
      const loadingTask = pdfjs.getDocument(URL.createObjectURL(file)); // Cargar el PDF usando pdf.js
      loadingTask.promise
        .then((pdf) => {
          // Establecer el número de páginas del PDF
          setNumPages(pdf.numPages);
          if (handleNumPagesChange) {
            handleNumPagesChange(pdf.numPages); // Llamar a onNumPagesChange si se pasa como prop
          }
        })
        .catch((error) => {
          console.error('Error loading PDF:', error);
        })
        .finally(() => {
          setIsLoading(false); // Finaliza el estado de carga
          setIsLoadingPages(false);
        });
      
    }else if (file && file.type.startsWith('image/')){
      handleNumPagesChange(1);
      setNumPages(1);
      setIsLoading(false);
      setIsLoadingPages(false);
    }
    console.timeEnd("Execution Time");
 
    setUploadedFiles((prevFiles) => [...prevFiles, file]);
    setSelectedPdf(URL.createObjectURL(file));
    setFile(file)
    setFilename(file.name);
  };
  
  // Función para actualizar el total
  const updateTotal = async (totalFinal, email) => {
    try {
      console.time("Execution Time"); 
      const response = await axios.get('https://databasemegapapel-production.up.railway.app/api/update-total', {
        params: {
          total: totalFinal,
          email: email // Pasar idDocs como parámetro
        },
      });
      console.log("Total actualizado exitosamente:", response.data);
      console.timeEnd("Execution Time");
    } catch (error) {
      console.error("Error al actualizar el total:", error.response ? error.response.data : error.message);
    }
  };
  const selectIdDocsEmail = async (email) => {
    try {
      console.time("Execution Time");
      const response = await axios.get('https://databasemegapapel-production.up.railway.app/api/idDocsEmail', {
        params: { email },
      });
      console.timeEnd("Execution Time");
      if (response.data && response.data.id) {
        return response.data.id;
      } else {
        console.error("No se encontró el ID Docs para este email.");
        return null;
      }
      
    } catch (error) {
      console.error("Error en la consulta al servidor:", error);
      localStorage.removeItem('uploadedFiles');
      setUploadedFiles([]); 
      localStorage.removeItem('uploadedFilesInfo');
      setUploadedFilesInfo([]); 
      navigate('/error'); // Manejo de redirección
      return null;
    }
  };

  //const handleAddAnotherFile = async(file,form) => {
  const handleAddAnotherFile = async(file,updatedFormData) => {
    setIsLoading(true); // Inicia la carga
    setIsLoadingPages(true);    
    // Hacer una copia del formData para evitar mutaciones directas

    setFormData(updatedFormData);
    const formDataCopy = { ...updatedFormData };
    const totalP = await calculateTotal(formDataCopy);
    // Aquí se calcula el número de páginas y se actualiza el estado
    let numPagesA = 1; 
    if (file && file.type === 'application/pdf') {
      console.time("Execution Time"); 
      const loadingTask = pdfjs.getDocument(URL.createObjectURL(file)); // Cargar el PDF usando pdf.js
      const pdf = await loadingTask.promise;
      numPagesA = pdf.numPages;
      setNumPages(numPagesA);
      console.timeEnd("Execution Time"); 
    }
    else if (file && file.type.startsWith('image/')){
      handleNumPagesChange(1);
      setNumPages(1);
      setIsLoading(false);
      setIsLoadingPages(false);
    }

    if (add===0){
      await addRowToDatabaseDocs(total,formDataCopy.email);
      console.log("addAnother",add)
      console.log("Add doc")
    }

    const idDocs = await selectIdDocsEmail(formDataCopy.email);

    const newUploadedFilesInfo = {
      id:idCounter++,
      idDocs: idDocs,
      name:filename,
      color:formDataCopy.color,
      size: formDataCopy.size,
      material: formDataCopy.material,
      copiesImage: formDataCopy.copiesImage,
      orientation: formDataCopy.orientation,
      copies: formDataCopy.copies,
      numPagesF: formDataCopy.numPagesF,
      sides: formDataCopy.doubleSided ? 'Doble cara' : 'Una cara',
      hooped: formDataCopy.hooped  ? 'Argollado' : 'Sin argollar',
      email:formDataCopy.email,
      comments: formDataCopy.comments  === '' ? 'Sin comentarios' :formDataCopy.comments,
      totalP:totalP,
      allPages:formDataCopy.allPages,
      specificPages: formDataCopy.specificPages === '' ? 'Todas' : formDataCopy.specificPages ,
      startPage:formDataCopy.startPage,
      endPage:formDataCopy.endPage,
      individualPages:formDataCopy.individualPages,
      inputPage:formDataCopy.inputPage
      
    };
    
    setUploadedFilesInfo((prevFiles) => [...prevFiles, newUploadedFilesInfo]);

    setUploadedFiles((prevFiles) => [...prevFiles, file]);
   
    setSelectedPdf(URL.createObjectURL(file));
    setFile(file)
    setFilename(file.name);

    setAdd(add+1)
    
    let calculatedTotal = await calculateTotal(formData);    
    setTotal(calculatedTotal);

    //Subir el archivo a google Drive
    await uploadPdfToServer(file,formDataCopy, idDocs);
    setIsLoading(false); 
    setIsLoadingPages(false);
    
  };
 
  const handleSubmitPrintForm = async (updatedFormData) => {
    
    // Actualiza el estado con el formData correcto
    setFormData(updatedFormData);
  
    
    // Hacer una copia del formData para evitar mutaciones directas
    const formDataCopy = { ...updatedFormData };
    const totalP = await calculateTotal(formDataCopy);
  
    try {
      console.time("Execution Time");
      // Llamar a la función para subir el archivo al Drive y grabar la fila en prints
      if (add===0){
        await addRowToDatabaseDocs(total,formDataCopy.email);
        console.log("Add doc")
  
      }
    
      const idDocs = await selectIdDocsEmail(formDataCopy.email);

      try {
        await uploadPdfToServer(file,formDataCopy, idDocs);
        console.log('Upload a Drive exitoso');
      } catch (error) {
        console.error('Error al subir archivo:', error);
        navigate(-1);
        // Aquí puedes agregar lógica adicional si es necesario
        return; // Detener la ejecución del código restante
      }
      
      const responseDocs = await axios.get('https://databasemegapapel-production.up.railway.app/api/sum-prints', {
        params: {
          idDocs: idDocs // Pasar idDocs como parámetro
        }
      });


      const uploadedFilesInfo = {
        id:idCounter++,
        idDocs: idDocs,
        name:filename,
        color:formDataCopy.color,
        size: formDataCopy.size,
        material: formDataCopy.material,
        copiesImage: formDataCopy.copiesImage,
        orientation: formDataCopy.orientation,
        copies: formDataCopy.copies,
        numPagesF: formDataCopy.numPagesF,
        sides: formDataCopy.doubleSided ? 'Doble cara' : 'Una cara',
        hooped: formDataCopy.hooped  ? 'Argollado' : 'Sin argollar',
        email:formDataCopy.email,
        comments: formDataCopy.comments  === '' ? 'Sin comentarios' :formDataCopy.comments,
        totalP:totalP,
        allPages:formDataCopy.allPages,
        specificPages: formDataCopy.specificPages === '' ? 'Todas' : formDataCopy.specificPages ,
        startPage:formDataCopy.startPage,
        endPage:formDataCopy.endPage,
        individualPages:formDataCopy.individualPages,
        inputPage:formDataCopy.inputPage
        
      };

      setUploadedFilesInfo((prevFiles) => [...prevFiles, uploadedFilesInfo]);

      // Accede a la propiedad correcta de la respuesta
      const totalFinal = responseDocs.data.sumTotal; // Asegúrate de que la propiedad sea sumTotal
      setTotal(totalFinal)
      updateTotal(totalFinal,formDataCopy.email)
 
      console.timeEnd("Execution Time");
      // Navegar a la página de pago después de que todo esté completo
      navigate('/payment');
      
    } catch (error) {
      console.error('Error en el manejo del formulario de impresión:', error);
    }
  };
  

  return (
    
  <Routes>
    <Route path="/" element={<HomePage onFileUpload={handleFileUpload} />} />
    {/* <Route path="/email-view" element={<EmailViewPage onSelectPdf={handleSelectPdf} />} /> */}
    <Route 
        path="/pdf-select" 
        element={<PdfSelectPage 
          selectedPdf={selectedPdf} 
          onSubmitPrintForm={handleSubmitPrintForm}
          onAddAnotherFile={handleAddAnotherFile}  
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles} 
          uploadedFilesInfo={uploadedFilesInfo}
          setUploadedFilesInfo={setUploadedFilesInfo} 
          numPages={numPages}
          file={file}
          handleFileUpload={handleFileUpload}
          setIsLoading={setIsLoading} 
          isLoading={isLoading} 
          isLoadingPages={isLoadingPages}
          
        />} 
      />

    <Route path="/payment" element={<PaymentPage 
    total={total} 
    setTotal={setTotal}
    setUploadedFiles={setUploadedFiles} 
    formData={formData} 
    uploadedFilesInfo={uploadedFilesInfo}
    setUploadedFilesInfo={setUploadedFilesInfo}
    updateTotal={updateTotal}
    deleteRow={deleteRow}
    
    />} />
    <Route path="/local-payment-info" element={<LocalPaymentInfo 
    setUploadedFiles={setUploadedFiles}
    />} />
    <Route path="/qr-payment-info" element={<QRPaymentInfo 
    setUploadedFiles={setUploadedFiles}
    />} />
    <Route path="/error" element={<Error 
    setUploadedFiles={setUploadedFiles}
    />} />
    <Route path="/prices" element={<PricesPage />} />
    
    
  </Routes>
  );
}

export default App;

