import React, { useState, useEffect } from 'react';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;



function PdfViewer({ selectedPdf, onNumPagesChange,file }) {
  const [numPages, setNumPages] = useState(null);

  
  useEffect(() => {
    

    if (file && file.type) {
      if (file && file.type === 'application/pdf') {
        const loadingTask = pdfjs.getDocument(selectedPdf);

        loadingTask.promise.then((pdf) => {
          setNumPages(pdf.numPages);
          if (onNumPagesChange) {
            onNumPagesChange(pdf.numPages);
          }
        }).catch((error) => {
          console.error('Error loading PDF:', error);
        });
        
      }else if (file && file.type.startsWith('image/')){
        onNumPagesChange(1);
        setNumPages(1);
      }
    }
  }, [selectedPdf,onNumPagesChange,file]);

 

  return (
    <div className='email-view-page'>
      <div className="scroll-container">
      { selectedPdf && (
        file.type === 'application/pdf' ? (
          <embed src={selectedPdf} type="application/pdf" />
        ) : (
          <img src={selectedPdf} alt="Uploaded" />
          
        )
      )}
      </div>
      {numPages && <p>Total de páginas: {numPages}</p>}
    </div>
  );
}

export default PdfViewer;