import React, { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import PdfSelectPage from './pages/PdfSelectPage';
import PaymentPage from './pages/PaymentPage';
import LocalPaymentInfo from './pages/LocalPayment';
import '../src/styles/styles.css'; 
import axios from 'axios';
import moment from 'moment-timezone';
import PricesPage from './pages/Precios'; // Página de precios

// Función que calcula el total según condiciones de precios
async function calculateTotal(formData) {
  try {
    //Request a base de datos para consultar los precios
    const response = await axios.get('https://databasemegapapel-production.up.railway.app/api/prices');
    const pricesData = response.data;
    const prices = {};
    pricesData.forEach(price => {
      const { material, size, color, sides, total } = price;
      prices[material] = prices[material] || {};
      prices[material][size] = prices[material][size] || {};
      prices[material][size][color] = prices[material][size][color] || {};
      prices[material][size][color][sides] = total;
    });

    
    const { material, size, color, copies, doubleSided, numPagesF,hooped } = formData;
    console.log("formData",formData)
    const sides = doubleSided ? 'Doble cara' : 'Una cara';
    let priceHooped=0
    if (hooped) {
      if (numPagesF > 200) {
        priceHooped = 8000;
      } else if (numPagesF > 150 && numPagesF <= 200) {
        priceHooped = 6000;
      } else if (numPagesF > 100 && numPagesF <= 150) {
        priceHooped = 5000;
      } else if ( numPagesF <= 100) {
        priceHooped = 3000;
      }
    }
    console.log("priceHooped",priceHooped)
    
    if (!prices[material] || !prices[material][size] || !prices[material][size][color] || !prices[material][size][color][sides]) {
      console.log('Material, tamaño, color o tipo de impresión no válidos');
      return 'Material, tamaño, color o tipo de impresión no válidos';
    }

    const pricePerSide = prices[material][size][color][sides];
    console.log("pricePerSide",pricePerSide)
    let price;
    if (doubleSided) {
      if (numPagesF % 2 !== 0) {
        if(numPagesF<2){
          price = (prices[material][size][color]['Una cara']+priceHooped)*copies;
        }else{
          price = ((Math.ceil(numPagesF/2) * pricePerSide)+prices[material][size][color]['Una cara']+priceHooped)*copies;
        }
        
      }else{
        price = ((numPagesF * pricePerSide)+priceHooped)* copies;
      }
    } else {
      price = ((numPagesF * pricePerSide)+priceHooped)* copies;
    }

   
    console.log("price",price)
    return price;
  } catch (error) {
    console.error('Error al calcular el total:', error);
    return 'Error al calcular el total'; // Devuelve un valor por defecto o maneja el error de otra manera
  }
}


function App() {
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [file, setFile] = useState(null);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate(); // Correcto aquí
  const [numPages, setNumPages] = useState(0);
  const [filename, setFilename] = useState(''); 
  const [add, setAdd] = useState(0); 
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFilesInfo, setUploadedFilesInfo] = useState([]);
  const [formData, setFormData] = useState({
    color: '',
    size: '',
    material: '',
    copiesImage:'1',
    orientation:'Vertical',
    copies: 0,
    numPagesF:numPages, 
    doubleSided: false,
    hooped:false,
    email:'',
    comments:'',
    totalP:0,
    allPages:true,
    specificPages:'Todas',
    startPage:'',
    endPage:'',
    individualPages:[],
    inputPage:''

  });
  let idCounter = 0;

   // Funcion para eliminar fila en prints
   const deleteRow = async (email,index,idDocs) => {
    
    try {
      await axios.delete(`https://databasemegapapel-production.up.railway.app/api-prints/delete-row/${email}/${index}/${idDocs}`);
      console.log(`Fila con id ${index} eliminada exitosamente`);
    } catch (error) {
      console.error(`Error al eliminar fila con id ${index}:`, error);
    }
  };

  // Función para actualizar el número de páginas ??
  const handleNumPagesChange = (pages) => {
    setNumPages(pages);
    console.log("pages",pages)
  };
  
  const addRowToDatabasePrints = async (doc,currentFormData,idDocsMax) => {
    console.log("currentFormData",currentFormData)
    console.log("doc",doc)
    
    if (
      
      doc.fileId &&
      currentFormData.size &&
      currentFormData.material &&
      currentFormData.color &&
      currentFormData.copies &&
      currentFormData.orientation &&
      currentFormData.numPagesF &&
      !isNaN(Math.ceil(currentFormData.numPagesF / (currentFormData.doubleSided ? 2 : 1))) &&
      await calculateTotal(currentFormData) !== undefined // Asegúrate de que el total no sea undefined

    ) {
      
    
      const total = await calculateTotal(currentFormData);
      console.log("total",total)
      console.log("doc",doc)
      
      const sheetData = {
        idDocs:idDocsMax,
        doc: `https://drive.google.com/file/d/${doc.fileId}/view`, 
        filename:filename,
        size: currentFormData.size,
        material: currentFormData.material,
        copiesImage:currentFormData.copiesImage,
        orientation:currentFormData.orientation,
        color: currentFormData.color,
        copies: currentFormData.copies,
        sides: currentFormData.doubleSided ? 'Doble cara' : 'Una cara',
        pages: currentFormData.numPagesF,
        specificPages:currentFormData.specificPages,
        hooped:currentFormData.hooped,
        nHojas: currentFormData.doubleSided? (currentFormData.numPagesF % 2 === 1 ? (Math.floor(currentFormData.numPagesF / 2) + 1) * currentFormData.copies : (currentFormData.numPagesF / 2) * currentFormData.copies): currentFormData.numPagesF * currentFormData.copies,
        comments:currentFormData.comments,
        total: total
      };
      /*
      const sheetData = new FormData();

      // Agrega el archivo PDF con la clave `pdf`
      sheetData.append('pdf', doc); // `doc` debe ser el objeto `File`.

      // Agrega los otros campos al `FormData`
      sheetData.append('idDocs', idDocsMax);
      sheetData.append('size', currentFormData.size);
      sheetData.append('material', currentFormData.material);
      sheetData.append('copiesImage', currentFormData.copiesImage);
      sheetData.append('color', currentFormData.color);
      sheetData.append('copies', currentFormData.copies);
      sheetData.append('sides', currentFormData.doubleSided ? 'Doble cara' : 'Una cara');
      sheetData.append('pages', currentFormData.numPagesF);
      sheetData.append('specificPages', currentFormData.specificPages);
      sheetData.append('hooped', currentFormData.hooped ? 1 : 0);
      sheetData.append(
        'nHojas',
        currentFormData.doubleSided
          ? (currentFormData.numPagesF % 2 === 1
              ? (Math.floor(currentFormData.numPagesF / 2) + 1) * currentFormData.copies
              : (currentFormData.numPagesF / 2) * currentFormData.copies)
          : currentFormData.numPagesF * currentFormData.copies
      );
      sheetData.append('comments', currentFormData.comments);
      sheetData.append('total', total);
      */
      console.log("sheetData", sheetData);
      
      try{
        await axios.post('https://databasemegapapel-production.up.railway.app/api-prints/add-row', 
        sheetData);
        console.log("Fila prints agregada a la base de datos exitosamente", sheetData);
      } catch (error) {
        console.error('Error al agregar fila a la base de datos:', error);
      }
    } else {
      console.error('Datos inválidos para enviar a la base de datos');
    }
  
  
};
const addRowToDatabaseDocs = async (totalFinal,email) => {
  //console.log("formDataDocs",formDataDocs)

    const sheetDataDocs = {
      //doc:doc.fileId,
      date: moment().tz('America/Bogota').format('YYYY-MM-DD HH:mm:ss'),
      email:email,
      employee: "",
      total: totalFinal,
      state: ""
    };

    
    try {
      await axios.post('https://databasemegapapel-production.up.railway.app/api-docs/add-row', sheetDataDocs);
      console.log("Fila docs agregada a la base de datos exitosamente", sheetDataDocs);
    } catch (error) {
      console.error('Error al agregar fila a la base de datos:', error);
    }

};
 

 // Función para subir el archivo a Google Drive
 const uploadPdfToServer = async (file,currentFormData, idDocsMax) => {
  console.log("currentFormData uploadPdfToServer",currentFormData)
  console.log("idDocsMax",idDocsMax)
  console.log("file",file)
  try {
    
    const response = await fetch(selectedPdf);
    const blob = await response.blob();
    const formDataFile = new FormData();
    formDataFile.append('file', blob, filename); // Asegúrate de que 'file' coincide con el campo de formulario en el backend
    //console.log("filename", formDataFile);
    const uploadResponse = await axios.post('https://backendgoogle-production.up.railway.app/api/upload', formDataFile);
    //console.log("uploadResponse",uploadResponse.data)
    await addRowToDatabasePrints(uploadResponse.data,currentFormData,idDocsMax); // Suponiendo que el response.data tenga el link de Google Drive
    //await addRowToDatabasePrints(file,currentFormData,idDocsMax); 
    
  } catch (error) {
    console.error('Error uploading PDF:', error);
  }
    
};

  const handleFileUpload = (file) => {
    console.log("file",file)
    setUploadedFiles((prevFiles) => [...prevFiles, file]);
    setSelectedPdf(URL.createObjectURL(file));
    setFile(file)
    setFilename(file.name);
  };
  
  // Función para actualizar el total
  const updateTotal = async (totalFinal, email) => {
    try {
      const response = await axios.get('https://databasemegapapel-production.up.railway.app/api/update-total', {
        params: {
          total: totalFinal,
          email: email // Pasar idDocs como parámetro
        },
      });
      console.log("Total actualizado exitosamente:", response.data);
    } catch (error) {
      console.error("Error al actualizar el total:", error.response ? error.response.data : error.message);
    }
  };
  const selectIdDocsEmail = async (email) => {
    try {
      const response = await axios.get('https://databasemegapapel-production.up.railway.app/api/idDocsEmail', {
        params: {
          email: email,
        },
      });
      console.log("ID Docs obtenido exitosamente:", response.data);
      
      return response.data[0].id; // Asegúrate de que esto se ajuste a tu respuesta
    } catch (error) {
      console.error("Error al obtener idDocs:", error.response ? error.response.data : error.message);
      return null; // Retornar null en caso de error
    }
  };

  //const handleAddAnotherFile = async(file,form) => {
  const handleAddAnotherFile = async(file,updatedFormData) => {
    
    console.log("Se agregó otro archivo")
    
    // Hacer una copia del formData para evitar mutaciones directas
    //const formDataCopy = { ...form };
    setFormData(updatedFormData);
    const formDataCopy = { ...updatedFormData };
    console.log("formData another app copy",formDataCopy)
    const totalP = await calculateTotal(formDataCopy);
    //const response = await axios.get('https://databasemegapapel-production.up.railway.app/api/idDocsMaxDocs');
    //const idDocs = response.data.id; // Obtener el idDocs del response
    if (add===0){
      await addRowToDatabaseDocs(total,formDataCopy.email);
      console.log("addAnother",add)
    }

    const idDocs = await selectIdDocsEmail(formDataCopy.email);
    //const responsePrints = await axios.get('https://databasemegapapel-production.up.railway.app/api/idMaxPrints');
    //const idPrints = responsePrints.data.id; // Obtener el idDocs del response
    const newUploadedFilesInfo = {
      id:idCounter++,
      idDocs: idDocs,
      name:filename,
      color:formDataCopy.color,
      size: formDataCopy.size,
      material: formDataCopy.material,
      copiesImage: formDataCopy.copiesImage,
      orientation: formDataCopy.orientation,
      copies: formDataCopy.copies,
      numPagesF: formDataCopy.numPagesF,
      sides: formDataCopy.doubleSided ? 'Doble cara' : 'Una cara',
      hooped: formDataCopy.hooped  ? 'Argollado' : 'Sin argollar',
      email:formDataCopy.email,
      comments: formDataCopy.comments  === '' ? 'Sin comentarios' :formDataCopy.comments,
      totalP:totalP,
      allPages:formDataCopy.allPages,
      specificPages: formDataCopy.specificPages === '' ? 'Todas' : formDataCopy.specificPages ,
      startPage:formDataCopy.startPage,
      endPage:formDataCopy.endPage,
      individualPages:formDataCopy.individualPages,
      inputPage:formDataCopy.inputPage
      
    };
    
  
    console.log("totalP",await calculateTotal(formDataCopy))
    console.log("uploadedFilesInfo",uploadedFilesInfo)
    
    setUploadedFilesInfo((prevFiles) => [...prevFiles, newUploadedFilesInfo]);

    setUploadedFiles((prevFiles) => [...prevFiles, file]);
   
    setSelectedPdf(URL.createObjectURL(file));
    setFile(file)
    setFilename(file.name);

    setAdd(add+1)
    
    let calculatedTotal = await calculateTotal(formData);    
    setTotal(calculatedTotal);
    console.log("calculatedTotal",calculatedTotal)

    //Subir el archivo a google Drive
    await uploadPdfToServer(file,formDataCopy, idDocs);
    
  };
 
  const handleSubmitPrintForm = async (updatedFormData) => {
    // Actualiza el estado con el formData correcto
    setFormData(updatedFormData);
  
    
    // Hacer una copia del formData para evitar mutaciones directas
    const formDataCopy = { ...updatedFormData };
    const totalP = await calculateTotal(formDataCopy);
    //const currentDate = moment().tz('America/Bogota').format('YYYY-MM-DD HH:mm:ss');
    
    
  
    try {

      // Llamar a la función para subir el archivo al Drive y grabar la fila en prints
      if (add===0){
        await addRowToDatabaseDocs(total,formDataCopy.email);
  
      }
      //const response = await axios.get('https://databasemegapapel-production.up.railway.app/api/idDocsMaxDocs');
      //const idDocs = response.data.id; // Obtener el idDocs del response
    
      const idDocs = await selectIdDocsEmail(formDataCopy.email);
      console.log("idDocs", idDocs);
      
      await uploadPdfToServer(file,formDataCopy, idDocs);
      
      const responseDocs = await axios.get('https://databasemegapapel-production.up.railway.app/api/sum-prints', {
        params: {
          idDocs: idDocs // Pasar idDocs como parámetro
        }
      });
      //const responsePrints = await axios.get('https://databasemegapapel-production.up.railway.app/api/idMaxPrints');
      //const idPrints = responsePrints.data.id; // Obtener el idDocs del response
      const uploadedFilesInfo = {
        id:idCounter++,
        idDocs: idDocs,
        name:filename,
        color:formDataCopy.color,
        size: formDataCopy.size,
        material: formDataCopy.material,
        copiesImage: formDataCopy.copiesImage,
        orientation: formDataCopy.orientation,
        copies: formDataCopy.copies,
        numPagesF: formDataCopy.numPagesF,
        sides: formDataCopy.doubleSided ? 'Doble cara' : 'Una cara',
        hooped: formDataCopy.hooped  ? 'Argollado' : 'Sin argollar',
        email:formDataCopy.email,
        comments: formDataCopy.comments  === '' ? 'Sin comentarios' :formDataCopy.comments,
        totalP:totalP,
        allPages:formDataCopy.allPages,
        specificPages: formDataCopy.specificPages === '' ? 'Todas' : formDataCopy.specificPages ,
        startPage:formDataCopy.startPage,
        endPage:formDataCopy.endPage,
        individualPages:formDataCopy.individualPages,
        inputPage:formDataCopy.inputPage
        
      };
      console.log("uploadedFilesInfo",uploadedFilesInfo)

      setUploadedFilesInfo((prevFiles) => [...prevFiles, uploadedFilesInfo]);
      //console.log(responseDocs.data)
      const totalFinal = responseDocs.data[0]['sum(total)']; // Accede al valor 240
      console.log("totalFinal",totalFinal)
      setTotal(totalFinal)
      updateTotal(totalFinal,formDataCopy.email)
      // Update total 
      /*
      await axios.get('https://databasemegapapel-production.up.railway.app/api/update-total', {
        params: {
          total:totalFinal,
          idDocs: idDocs // Pasar idDocs como parámetro
        }
      });*/
  
      
      // Navegar a la página de pago después de que todo esté completo
      navigate('/payment');
  
    } catch (error) {
      console.error('Error en el manejo del formulario de impresión:', error);
    }
  };


  return (
    
  <Routes>
    <Route path="/" element={<HomePage onFileUpload={handleFileUpload} />} />
    {/* <Route path="/email-view" element={<EmailViewPage onSelectPdf={handleSelectPdf} />} /> */}
    <Route 
        path="/pdf-select" 
        element={<PdfSelectPage 
          selectedPdf={selectedPdf} 
          onSubmitPrintForm={handleSubmitPrintForm}
          onNumPagesChange={handleNumPagesChange} 
          onAddAnotherFile={handleAddAnotherFile}  
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles} 
          uploadedFilesInfo={uploadedFilesInfo}
          setUploadedFilesInfo={setUploadedFilesInfo} 
          numPages={numPages}
          setNumPages={setNumPages}
          file={file}
          handleFileUpload={handleFileUpload}
          
        />} 
      />

    <Route path="/payment" element={<PaymentPage 
    total={total} 
    setTotal={setTotal}
    setUploadedFiles={setUploadedFiles} 
    formData={formData} 
    uploadedFilesInfo={uploadedFilesInfo} 
    setUploadedFilesInfo={setUploadedFilesInfo}
    updateTotal={updateTotal}
    deleteRow={deleteRow}
    />} />
    <Route path="/local-payment-info" element={<LocalPaymentInfo 
    setUploadedFiles={setUploadedFiles}
    />} />
    <Route path="/prices" element={<PricesPage />} />
    
    
  </Routes>
  );
}

export default App;

